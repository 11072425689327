const isWithinLast60Minutes = () => {
  const savedTime = localStorage.getItem('visitTime')

  if (!savedTime) return false

  const savedTimestamp = parseInt(savedTime, 10)
  const currentTime = Date.now()
  const timeDifference = currentTime - savedTimestamp

  return timeDifference < 60 * 60 * 1000
}

export default isWithinLast60Minutes
