const userExistInBackend = async (email: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/account/exist/user/data?email=${email}`,
      {
        method: 'GET',
      },
    )

    return await response.json()
  } catch (e) {
    return e
  }
}

export default userExistInBackend
