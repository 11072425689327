import React, { useEffect, useRef, useState } from 'react'
import Image from '@/components/Image/Image'
import { Transition } from 'react-transition-group'
import { checkExistSubscription, checkIfCustomerExist } from '@/helpers/stripeHelpers'
import userExistInBackend from '@/helpers/userExistInBackend'
import { CHECKOUT_URL } from '@/constants/variables'
import { useLocation } from 'react-router-dom'
import isWithinLast60Minutes from '@/helpers/isWithinLast60Minutes'

const transition = '300ms all'
const defaultStyle = {
  transition,
  opacity: 0,
}
const duration = 300
const transitionStyles = {
  entering: {
    opacity: 1,
    transition,
  },
  entered: {
    opacity: 1,
    transition,
  },
  exiting: {
    opacity: 0,
    transition: '300ms all',
  },
  exited: {
    opacity: 0,
    display: 'none',
    transition: '300ms all, display 300ms',
  },
}

export const StartLoading = () => {
  const location = useLocation()

  const nodeRef = useRef(null)
  const [value, setValue] = useState(0)

  let difference = 0
  const startProgress = () => {
    const intervalId = setInterval(() => {
      ++difference
      if (difference >= 100) {
        clearInterval(intervalId)
        setValue(difference)
      } else if (difference > 40 && difference < 75) {
        clearInterval(intervalId)
        setValue(difference)
        startProgressSlow()
      } else {
        setValue(difference)
      }
    }, 50)
    return () => clearInterval(intervalId)
  }
  const startProgressSlow = () => {
    const intervalId = setInterval(() => {
      ++difference
      if (difference > 75) {
        clearInterval(intervalId)
        setValue(difference)
        startProgress()
      } else {
        setValue(difference)
      }
    }, 120)
    return () => clearInterval(intervalId)
  }

  const checkSubscription = async () => {
    try {
      const email = localStorage.getItem('email')

      if (email && isWithinLast60Minutes()) {
        const { data } = await checkIfCustomerExist({ email })

        if (data.length && Array.isArray(data)) {
          const { data: subscriptions } = await checkExistSubscription(data[0].id)

          const hasActiveSubscription = subscriptions.some((el: any) => el?.status === 'active')

          if (hasActiveSubscription && email) {
            const data = await userExistInBackend(email)

            if (data?.account?._id) {
              window.location.replace(`${CHECKOUT_URL}signup?id=${data?.account?._id}`)
            }
          }
        }
      }
    } catch (error) {
      console.error('Error handling customer subscription:', error)
    }
  }

  useEffect(() => {
    if (['/', '/start', '/start/'].some((path) => location.pathname === path)) {
      sessionStorage.removeItem('loaded')
      startProgress()
      checkSubscription()
    }
  }, [])

  useEffect(() => {
    if (value < 100 || sessionStorage.getItem('loaded')) return
    sessionStorage.setItem('loaded', 'true')
  }, [value])

  if (sessionStorage.getItem('loaded')) return null

  return (
    <Transition nodeRef={nodeRef} in={value < 100} unmountOnExit timeout={duration}>
      {(state: string) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...transitionStyles[state],
          }}
          className="flex overflow-hidden p-5 bg-black fixed top-0 left-[50%] -translate-x-2/4  h-full w-full z-[1000] max-w-[450px]"
        >
          <Image
            className="absolute top-0 left-0 h-full w-full object-center object-cover z-[-1]"
            src="/img/start-loading.jpg"
            webp="/img/start-loading.webp"
            alt="loading image"
          />
          <Image
            className="absolute top-[10px] left-[30px] "
            width={41}
            src="/img/logo-start.png"
            webp="/img/logo-start.webp"
            alt="logo image"
          />
          <div className="mt-auto mb-20">
            <h1 className="text-center text-xl4 text-white font-bold mb-10">
              Welcome to your Wall Pilates journey
            </h1>
            <p className="text-center text-xl text-white font-semibold mb-10">
              Take the FREE body type quiz to get your personalized plan
            </p>
            <p className="text-center text-md text-white font-semibold mb-5">Loading the quiz</p>
            <div className="p-[3px] bg-[#F6F7F9] w-[calc(100vw-2.5rem)] max-w-[calc(450px-2.5rem)] rounded-r20 mt-2 relative">
              <p className="absolute text-center top-[50%] -translate-y-1/2 w-[calc(100vw-2.5rem)] max-w-[calc(450px-2.5rem)] text-green font-semibold text-xl12">
                {value + '%'}
              </p>
              <div
                style={{ width: value + '%' }}
                className="h-[26px] rounded-r20 block bg-green overflow-hidden relative"
              >
                <p className="absolute text-center top-[50%] -translate-y-1/2 w-[calc(100vw-2.5rem)] max-w-[calc(450px-2.5rem)] text-white font-semibold text-xl12">
                  {value + '%'}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  )
}
