import React, { useEffect, useState } from 'react'
import { Button } from '@/components/Button/Button'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useValidationMessages from '@/customHooks/useValidationMessages'
import { Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import logo from '@/assets/images/logo.svg'
import { Header } from '@/components/Header/Header'
import { setUserId, trackEmail, trackPurchase } from '@/helpers/facebookPixelEvents'
import { PaywallStatus } from '@/components/Paywall/Paywall'

interface IFormInput {
  email: string
  password: string
}
interface User {
  email: string
  password: string
}

export default function CreateAccount(props: { testMode?: boolean }) {
  const { t } = useTranslation()
  const validationErrors = useValidationMessages()
  const [searchParams] = useSearchParams()
  const isSuccessPaddle = searchParams.get('success')
  const hasID = searchParams.get('id')
  const isWelcome = searchParams.get('isWelcome')
  const [showPass, setShowPass] = useState(false)
  const paywallStatusString = sessionStorage.getItem('paywallStatus') ?? ''
  const paywallStatus = paywallStatusString
    ? (JSON.parse(paywallStatusString) as PaywallStatus)
    : { currentStep: 0 }
  const isFreeTrial = paywallStatus.currentStep >= 2
  const storedData = sessionStorage.getItem('quizResult')
  const customerId = sessionStorage.getItem('customerId') as string
  const answers: Answers = storedData ? JSON.parse(storedData) : false
  const userEmail = answers
    ? (getValue('email', answers) as string)
    : ((localStorage.getItem('email') as string) ?? '')
  const [showNotification, setShowNotification] = useState(false)
  const [registerErrors, setRegisterErrors] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const result =
    location.state?.res ||
    sessionStorage.getItem('subscriptionInfo') ||
    isSuccessPaddle === 'true' ||
    (isWelcome && hasID)
  const refreshToken = sessionStorage.getItem('refreshToken')

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(validationErrors.requiredEmail)
      .matches(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, validationErrors.invalidEmail),
    password: yup
      .string()
      .required(validationErrors.requiredPassword)
      .min(8, validationErrors.minLengthPassword),
  })
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { email: userEmail },
  })

  const inputClasses =
    'text-base rounded-xl p-3 border border-borderInput bg-transparent w-full placeholder:text-dark focus-visible:outline-none placeholder:font-extrabold placeholder:opacity-30'

  const createAccount = async (
    customer: User,
    customerId: string,
  ): Promise<{ status: number; body: any }> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/auth/local/sign-up?_id=${customerId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(customer),
        },
      )
      const data = response.json()
      return { status: response.status, body: data }
    } catch (e) {
      return { status: 412, body: { error: e } }
    }
  }

  const sendConfirmAccountEmail = async (email: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/auth/email/otp`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        },
      )
      const data = response.json()
      return { status: response.status, body: data }
    } catch (e) {
      return { status: 412, body: { error: e } }
    }
  }

  useEffect(() => {
    if (!props.testMode && !result) {
      navigate('/')
    }
    if (refreshToken) {
      navigate('/thank-you', { state: { token: refreshToken, customerId } })
    } else {
      // trackPurchase(price ?? 100)
      const hasPurchase = sessionStorage.getItem('hasPurchase')
      if (hasPurchase === null && !isWelcome) {
        sessionStorage.setItem('hasPurchase', 'has')
        trackPurchase(!isFreeTrial)
      }
    }
  }, [result, refreshToken])

  const onSubmit = (data: IFormInput) => {
    const customer = { email: data.email, password: data.password }
    trackEmail('mail_2', data.email as string)
    createAccount(customer, hasID ?? customerId).then((res) => {
      if (res.status !== 412) {
        res.body.then((body: any) => {
          if (res.status === 200) {
            const token = body.refresh
            const userId = body.userId

            sessionStorage.setItem('refreshToken', token)
            setUserId(userId)

            sendConfirmAccountEmail(customer.email).then(() => {
              navigate('/thank-you', { state: { token, customerId } })
            })
          } else {
            setShowNotification(true)
            setTimeout(() => {
              setShowNotification(false)
            }, 3000)
            setRegisterErrors(body.error)
          }
        })
      }
    })
  }
  return (
    <div className="w-full relative py-5 px-4 flex flex-col pt-3  h-full overflow-y-auto overflow-x-hidden pb-20">
      <div
        className={`${showNotification ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all  fixed top-5 left-1/2 -translate-x-1/2 z-50 px-4 box-border w-image max-w-image`}
      >
        <div className="bg-green rounded-r12 text-center text-md2 leading-6 text-white font-semibold    p-1 ">
          This email is already taken <br /> <small>Try another email</small>
        </div>
      </div>
      <h1 className="text-green relative w-image max-w-image -ml-4 pb-3 border-b border-borderInput text-center font-semibold">
        {t('Congrats')}!
      </h1>
      <div className="absolute top-4 right-4 w-full">
        <Header />
      </div>
      <h2 className="text-center mt-4 text-xl3 pt-0 mb-2 font-semibold">
        {t('One')} <mark className="text-green">{t('last step')}</mark> 😉
      </h2>
      <h2 className="text-xl3 pt-0 text-center text-black font-bold pt-0 mb-0  relative  font-semibold">
        {t('Create account')}
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col shrink grow basis-auto mt-4"
      >
        <label htmlFor="your-email" className="mt-0 opacity-75">
          {t('Email Address')}
        </label>
        <input
          className={`${errors.email ? 'border-mark' : ''} ${inputClasses}`}
          {...register('email')}
          id="your-email"
          placeholder="Enter your email"
        />
        {errors.email && (
          <p className="text-mark block text-xs3 font-semibold text-left">{errors.email.message}</p>
        )}
        <label htmlFor="your-password" className="mt-5 opacity-75">
          {t('Password')}
        </label>
        <div className="relative">
          <input
            className={`${errors.password ? 'border-mark' : ''} ${inputClasses}`}
            {...register('password')}
            id="your-password"
            type={showPass ? 'text' : 'password'}
            placeholder="Enter your password"
          />
          <button
            type="button"
            onClick={() => setShowPass((prevState) => !prevState)}
            className="absolute w-6 h-6 right-4 top-[13px]"
          >
            {!showPass ? (
              <svg
                className="w-full h-full"
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.0403 13.0001C21.0403 15.7843 18.7824 18.0407 15.9982 18.0407C13.2141 18.0407 10.9577 15.7843 10.9577 13.0001C10.9577 10.2144 13.2141 7.95804 15.9982 7.95804C18.7824 7.95804 21.0403 10.2144 21.0403 13.0001Z"
                  stroke="#B8B8B8"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9968 24.6437C22.069 24.6437 27.623 20.2777 30.75 12.9999C27.623 5.7222 22.069 1.3562 15.9968 1.3562H16.0032C9.93097 1.3562 4.377 5.7222 1.25 12.9999C4.377 20.2777 9.93097 24.6437 16.0032 24.6437H15.9968Z"
                  stroke="#B8B8B8"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                className="w-full h-full"
                width="32"
                height="26"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.4301 16.2928C11.5132 15.4453 10.9551 14.2936 10.9551 13.0017C10.9551 10.4134 13.2066 8.32717 16.0004 8.32717C17.3829 8.32717 18.6554 8.84541 19.5563 9.69291M20.9517 13.8301C20.5818 15.7347 18.9617 17.2378 16.9062 17.5833M7.47689 20.8781C4.94627 19.0384 2.80314 16.3512 1.25 13.001C2.81908 9.63614 4.97657 6.93418 7.52313 5.07972C10.0538 3.22526 12.9735 2.2183 16 2.2183C19.0441 2.2183 21.9622 3.24003 24.5088 5.10778M27.8769 8.35529C28.974 9.70479 29.9388 11.2625 30.7504 13.0003C27.6138 19.7286 22.071 23.7816 16.0004 23.7816C14.6243 23.7816 13.2673 23.5748 11.9629 23.1718M28.577 1.35498L3.42388 24.645"
                  stroke="#B8B8B8"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
        </div>

        {errors.password && (
          <p className="text-mark block text-xs3 font-semibold text-left">
            {errors.password.message}
          </p>
        )}
        <div className={'text-mark block mt-1 text-xs font-normal text-left'}>{registerErrors}</div>
        <div className="mt-3 pt-1">
          <div className="transition-all mx-auto rounded-r24 shadow-large w-32 h-32 cursor-pointer absolute top-0 left-0  active:scale-95 duration-300 relative">
            <div className="w-32 h-32 bg-white rounded-r24 border border-borderInput p-8">
              <span className="block h-4 w-14 bg-[#69E07F] rounded-r12 ml-2 mt-0.5"></span>
              <span className="block h-4 w-9 bg-[#69E07F] rounded-r12 ml-1 mt-1"></span>
              <span className="block h-4 w-4 bg-[#69E07F] rounded-r12  mt-1"></span>
            </div>
          </div>
          <img
            className="max-w-40 w-full mx-auto mt-8"
            width="156"
            height="27"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="shrink grow basis-auto mt-4 flex items-end button fixed bottom-4 max-w-content w-full container left-1/2 -translate-x-1/2 z-30">
          <Button
            type="submit"
            className={`${!getValues('email') || !getValues('password') || errors.email || errors.password ? 'opacity-35 before:hidden' : ''} btn-large`}
          >
            {t('continue with email')}
          </Button>
        </div>
      </form>
    </div>
  )
}
