import { Customer } from '@/types/types'
import { STRIPE_SECRET_KEY_MAIN } from '@/constants/variables'

type HeadersType = {
  Authorization: string
  'Content-Type': string
}

export class StripeRequestHeader {
  static getHeaders(): HeadersType {
    const secretKey = STRIPE_SECRET_KEY_MAIN
    return {
      Authorization: `Bearer ${secretKey}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
}

const endpoint = 'https://api.stripe.com/v1'

export const checkIfCustomerExist = async (customer: Customer) => {
  if (customer) {
    try {
      const response = await fetch(`${endpoint}/customers?email=${customer.email}`, {
        method: 'GET',
        headers: StripeRequestHeader.getHeaders(),
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}

export const checkExistSubscription = async (id: string) => {
  if (id) {
    try {
      const response = await fetch(`${endpoint}/subscriptions?customer=${id}`, {
        method: 'GET',
        headers: StripeRequestHeader.getHeaders(),
      })
      return await response.json()
    } catch (e) {
      return e
    }
  }
}
